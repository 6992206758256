import React from "react";
import "./Meet.css";
import {Fade} from "react-reveal";
import emoji from "react-easy-emoji";
import Calendly from "../../components/calendly/Calendly";

export default function Greeting() {
  return (
    <Fade bottom duration={1000} distance="40px">
      <div className="greet-main" id="meeting">
        <p className="meet-text">{emoji("Lets Meet🤝 / 💻")}</p>
        <div className="greeting-main">
          <div className="greeting-text-div">
            <Calendly />
          </div>
        </div>
      </div>
    </Fade>
  );
}
