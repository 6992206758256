/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

// Summary And Greeting Section

import emoji from "react-easy-emoji";

const illustration = {
  animated: true // set to false to use static SVG
};

const greeting = {
  username: "Nishant Gautam",
  title: "Hi there! I'm Nishant.",
  subTitle: emoji(
    "Co-Founder at Scribe Zero & Freelancer. A passionate Machine Learning Engineer 🚀 having experience in building ML Systems from scratch. I have helped companies design and develop Machine Learning capabilities. I also have expertise in designing systems for scale."
  ),
  resumeLink:
    "https://drive.google.com/file/d/1RSYlJT4MDlZEpzYi7OlfGtLzmbL9Kw4G/view?usp=sharing",
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/ngautam0",
  linkedin: "https://www.linkedin.com/in/nishant-gautam-70382281/",
  gmail: "mrnishantgautam@gmail.com",
  phone: "9972575118",
  secondary: "hello@nishantgautam.com",
  // Instagram and Twitter are also supported in the links!
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I Do ?",
  subTitle:
    "Well, there is a lot to talk about this; however, I am going to mention a few. ",
  skills: [
    emoji("⚡ Solving buisness problems using Machine Learning."),
    emoji("⚡ Grooming and leading team-mates towards product development."),
    emoji("⚡ Build beautiful stories out of messy data."),
    emoji("⚡ Teach people Python for Data Science and NLP."),
    emoji("⚡ Use Front End to present Insights, Numbers and AI Products."),
    emoji("⚡ Take care of AWS Deployments for prototype projects."),
    emoji(
      "⚡ Machine Learning : Python, Pandas, Numpy, SkLearn, Gensim, Keras, TF, PySpark, NLP"
    ),
    emoji(
      "⚡ Backend : Flask, Django, Redis, Mongo DB, PostgreSQL, AWS, Supervisord"
    ),
    emoji(
      "⚡ Front End : JavaScript, JSX, React JS, Redux, HTML, CSS, Bootstrap"
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "r",
      fontAwesomeClassname: "fab fa-r-project"
    },
    {
      skillName: "graphs",
      fontAwesomeClassname: "fas fa-chart-pie"
    },

    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Birla Institute of Technology and Science, Pilani",
      logo: "https://upload.wikimedia.org/wikipedia/en/thumb/d/d3/BITS_Pilani-Logo.svg/1200px-BITS_Pilani-Logo.svg.png",
      subHeader: "Masters of Technology in Software Engineering",
      desc: "Ranked 1st in the program. Took courses about Software Engineering, Operating Systems, Data Mining, etc.."
    },
    {
      schoolName: "Birla Institute of Technology, Mesra",
      logo: "https://upload.wikimedia.org/wikipedia/en/thumb/d/d2/Birla_Institute_of_Technology_Mesra.png/220px-Birla_Institute_of_Technology_Mesra.png",
      subHeader: "Bachelor in Computer Application",
      desc: "Ranked in the top 1% in the program. Took courses about various programming languages and computer basics."
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Data Science", //Insert stack or technology you have experience in
      progressPercentage: "95%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Machine Learning", //Insert stack or technology you have experience in
      progressPercentage: "95%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Programming / Algorithms",
      progressPercentage: "90%"
    },
    {
      Stack: "JavaScript & React JS", //Insert stack or technology you have experience in
      progressPercentage: "85%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Presentation",
      progressPercentage: "80%"
    },
    {
      Stack: "Peoples Skill",
      progressPercentage: "80%"
    },
    {
      Stack: "Backend",
      progressPercentage: "65%"
    },
    {
      Stack: "DataBase",
      progressPercentage: "55%"
    },
    {
      Stack: "Deployments",
      progressPercentage: "35%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Co-Founder",
      company: "Scribe Zero",
      companylogo: require("./assets/images/scribe_work.png"),
      url: "https://scribezero.com"
    },
    {
      role: "Machine Learning Engineer",
      company: "Quandoo",
      companylogo: require("./assets/images/quandoo.png"),
      url: "https://www.quandoo.de/en"
    },
    {
      role: "Machine Learning Engineer",
      company: "Nytro.ai",
      companylogo: require("./assets/images/nytro.png"),
      url: "https://www.nytro.ai/"
    },
    {
      role: "Machine Learning Lead",
      company: "StackRoute",
      companylogo: require("./assets/images/stackroute.png"),
      url: "https://stackroutelearning.com/"
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "false", // Set true or false to show Contact profile using Github, defaults to true
  display: false // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Big Projects",
  subtitle: "SOME STARTUPS AND COMPANIES THAT I HELPED TO CREATE THEIR TECH",
  projects: [
    {
      image:
        "https://static.wixstatic.com/media/24b4c2_d994348a5902426f9d186eb7ced0da93~mv2.png/v1/fill/w_420,h_482,al_c,q_85,usm_0.66_1.00_0.01/Contact%20Us%201_1-8.webp",
      projectName: "Building Contract Systems",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://www.scribezero.com/"
        }
      ]
    },
    {
      image:
        "https://www.nytro.ai/wp-content/themes/twentytwentyone/images/header-footer/nytro_black_logo.svg",
      projectName: "AI-POWERED PITCH INTELLIGENCE",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://www.nytro.ai/"
        }
      ]
    },

    {
      image:
        "https://www.nfa.co.uk/wp-content/themes/nfa/assets/images/nfg-brand.svg",
      projectName: "National Fostering Group",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://www.nfa.co.uk/"
        }
      ]
    },

    {
      image:
        "https://media-exp1.licdn.com/dms/image/C4E0BAQFllh2jkIuJoQ/company-logo_200_200/0?e=2159024400&v=beta&t=7mORiraxX1R_ZewDxF4m50ljLcpWsZu98OPdw8W9WnA",
      projectName: "ML Course Design and E-Learning Platform",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://stackroutelearning.com/"
        }
        //  you can add extra buttons here.
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "Google Code-In Finalist",
      subtitle:
        "First Pakistani to be selected as Google Code-in Finalist from 4000 students from 77 different countries.",
      image: require("./assets/images/codeInLogo.webp"),
      footerLink: [
        {
          name: "Certification",
          url: "https://drive.google.com/file/d/0B7kazrtMwm5dYkVvNjdNWjNybWJrbndFSHpNY2NFV1p4YmU0/view?usp=sharing"
        },
        {
          name: "Award Letter",
          url: "https://drive.google.com/file/d/0B7kazrtMwm5dekxBTW5hQkg2WXUyR3QzQmR0VERiLXlGRVdF/view?usp=sharing"
        },
        {
          name: "Google Code-in Blog",
          url: "https://opensource.googleblog.com/2019/01/google-code-in-2018-winners.html"
        }
      ]
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "With Love for Developing cool stuff, I love to write and teach others what I have learnt.",

  blogs: [
    {
      url: "https://medium.com/@mrnishantgautam/why-most-people-die-poor-a520515c596b",
      title: "Why Most people die poor ?",
      description:
        "Most people make common and very stupid finance mistakes. Are you making one ?"
    },
    {
      url: "https://medium.com/@mrnishantgautam/singularity-and-aftermath-88ac49ff6779",
      title: "Singularity and Aftermath",
      description:
        "A write up where I talk about the possibilities of life after Singularity"
    },
    {
      url: "https://www.youtube.com/embed/xc9_3HgP4lQ",
      title: "Python For Loop - Break, Else, Enumerate",
      description:
        "A video tutorial where I talk about python - Enumerate and Else.",
      isyotube: true
    },
    {
      url: "https://www.youtube.com/embed/1WlzdzEEUgk",
      title: "Python Walrus Operator",
      description:
        "A video tutorial where I talk about python - walrus operator.",
      isyotube: true
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  number: "+91-9972575118",
  email_address: "mrnishantgautam@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

export {
  illustration,
  greeting,
  socialMediaLinks,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails
};
